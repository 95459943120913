import React from 'react'
import styles from './index.scss'

class Component extends React.Component {
  render() {
    return (
      <div className={styles.netError}>
        <img className={styles.img} alt='' src='//img.zb100.com/product/buyH5/sad.png' />
        <p className={styles.message}>网络不可用，请检查网络设置</p>
        <div className={styles.btn} onClick={() => window.location.reload()}>重新加载</div>
      </div>
    )
  }
}

export default Component;
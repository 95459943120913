import React, {
  Component,
} from 'react';
import {
  connect,
} from 'react-redux';
import {
  withRouter,
} from 'react-router';
import {
  matchRoutes,
  renderRoutes,
} from 'react-router-config';
import {
  Toast,
} from 'antd-mobile';
import Cookies from 'universal-cookie';
import {
  Link,
} from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import getValue from 'get-value';
import 'isomorphic-fetch';
import {
  fetcher,
  checkAuth,
  getUserRegistryInfo,
} from '../../fetch';
import cookies, {
  setRawCookie,
} from '../../cookie';
import routes from '../../router';
import {
  changeStatus,
} from '../../modules/serverFetch';
import '../../styles/style.scss';

setRawCookie(new Cookies());

const userId = cookies.get('X-UID');
const userToken = cookies.get('X-Token');
const tokenTime = cookies.get('X-Time');

if (tokenTime) {
  fetcher.defaults.headers.common['X-Time'] = tokenTime;
}

if (userToken) {
  fetcher.defaults.headers.common['X-Token'] = userToken;
}

if (userId) {
  fetcher.defaults.headers.common['X-UID'] = userId;
}

const titleMap = {};

function getVirtualPath(pathname) {
  const title = titleMap[pathname];

  if (title) return title;

  const branch = matchRoutes(routes, pathname);
  const t = getValue(branch, '0.route.title');

  titleMap[pathname] = t;
  return t ? `/${t}` : pathname;
}

class App extends Component {
  componentDidMount() {
    const {
      serverFetch,
      location,
      dispatch,
      changeServerFetchStatus,
    } = this.props;

    /**
     * 处理iOS 微信客户端6.7.4 键盘收起页面未下移bug
     */
    ;(/iphone|ipod|ipad/i.test(navigator.appVersion)) && document.addEventListener('blur', (e) => {
      // 这里加了个类型判断，因为a等元素也会触发blur事件
      if (['input', 'textarea'].includes(e.target.localName)) {
        setTimeout(() => {
          document.body.scrollTop = document.body.scrollTop
        })
      }
    }, true);

    if (serverFetch.status === 'fail') {
      const branch = matchRoutes(routes, location.pathname);
      const promises = branch.map(({ route }) => {
        const fetchInitialData = route.component.fetchInitialData;

        return fetchInitialData instanceof Function ? fetchInitialData({
          dispatch,
        }) : Promise.resolve(null);
      });

      Promise.all(promises).then(() => {}).catch(e => {
        const msg = e.msg || e.message;

        if (msg) {
          Toast.fail(msg);
        }
      });
    }

    changeServerFetchStatus('');

    /*
    // 自定义访客统计
    checkAuth().then(auth => {
      if (auth && auth.operationStatus) {
        _czc.push(["_setCustomVar", "用户是否注册", "注册用户", 1]);
        _czc.push(["_trackPageview", getVirtualPath(location.pathname)]);
      } else {
        getUserRegistryInfo().then(userRegistryInfo => {
          if (!userRegistryInfo.mobileInfoComplete) {
            _czc.push(["_setCustomVar", "用户是否注册", "未填手机号用户", 1]);
            _czc.push(["_trackPageview", getVirtualPath(location.pathname)]);
          } else if (!userRegistryInfo.submittedCompanyInfo) {
            _czc.push(["_setCustomVar", "用户是否注册", `未填公司信息用户${cookies.get('X-UID')}`, 1]);
            _czc.push(["_trackPageview", getVirtualPath(location.pathname)]);
          } else {
            _czc.push(["_setCustomVar", "用户是否注册", `公司审核状态${userRegistryInfo.companyInfoAuditStatus}用户${cookies.get('X-UID')}`, 1]);
            _czc.push(["_trackPageview", getVirtualPath(location.pathname)]);
          }
        }).catch(() => {
          _czc.push(["_trackPageview", getVirtualPath(location.pathname)]);
        })
      }
    }).catch(() => {
      _czc.push(["_trackPageview", getVirtualPath(location.pathname)]);
    })
    */
  }

  componentWillReceiveProps(nextProps) {
    const navigated = nextProps.location !== this.props.location;

    if (navigated) {
      _czc.push(["_trackPageview", getVirtualPath(nextProps.location.pathname), getVirtualPath(this.props.location.pathname)]);

      if (nextProps.history.action !== 'POP') {
        const branch = matchRoutes(routes, nextProps.location.pathname);
        const promises = branch.map(({ route }) => {
          const fetchInitialData = route.component.fetchInitialData;
  
          return fetchInitialData instanceof Function ? fetchInitialData({
            dispatch: this.props.dispatch,
          }) : Promise.resolve(null);
        });
  
        Promise.all(promises).then(() => {}).catch(e => {
          const msg = e.msg || e.message;
  
          if (msg) {
            Toast.fail(msg);
          }
        });
      }
    }
  }

  render() {
    const {
      location,
      history,
    } = this.props
    const branch = matchRoutes(routes, location.pathname);
    const title = getValue(branch, '0.route.title') || '珠宝壹佰';

    return (
      <DocumentTitle title={title}>
        <div>
          {renderRoutes(routes)}
        </div>
      </DocumentTitle>
    );
  }
}

export default withRouter(connect(state => ({
  serverFetch: state.get('serverFetch'),
}), dispatch => ({
  dispatch,
  changeServerFetchStatus: status => {
    dispatch(changeStatus(status));
  },
}))(App));

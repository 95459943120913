let _rawCookie = {};

export const setRawCookie = (rawCookie) => {
    _rawCookie = rawCookie;
};

const get = (key, options) => {
    return _rawCookie.get(key, options);
}


const set = (key, value, options) => {
    return _rawCookie.set(key, value, options);
}

const remove = (key, options) => {
    return _rawCookie.remove(key, options);
}

export default {
    get,
    set,
    remove,
    setRawCookie
};
'use strict';

const axios = require('axios');
const getValue = require('get-value');

const baseURL = ({
  local: 'http://localhost:7001/api',
  daily: 'https://daily.m.zb100.com/api/',
  prod: 'https://m.zb100.com/api/',
})[process.env.EGG_SERVER_ENV];
const appid = ({
  local: 'wx14bfda127afb1092',
  daily: 'wx14bfda127afb1092',
  prod: 'wx208b9921d57e7003',
})[process.env.EGG_SERVER_ENV];
const secret = ({
  local: 'dcbcecc527aeaba1459e5ad7300f22b2',
  daily: 'dcbcecc527aeaba1459e5ad7300f22b2',
  prod: '98a846852a9a516e710b863626474831',
})[process.env.EGG_SERVER_ENV];

const fetcher = axios.create({
  baseURL,
});

fetcher.interceptors.response.use(response => {
  const url = getValue(response, 'config.url') || '';
  const baseURL = getValue(response, 'config.baseURL') || '';
  const data = getValue(response, 'data');

  if (url.indexOf(baseURL) !== 0) {
    // 站外请求
    return data;
  }

  if (getValue(data, 'code') !== '200') {
    return Promise.reject(data);
  }

  return getValue(data, 'data');
}, error => {
  return Promise.reject(error);
});

exports.getHomePage = function() {
  return fetcher.get('/home/inquiry');
};

exports.getFeatures = function(catId) {
  return fetcher.get(`/features/${catId}/values`);
};

exports.wechatOauth = function(code) {
  return fetcher.get('https://api.weixin.qq.com/sns/oauth2/access_token', {
    params: {
      appid,
      secret,
      code,
      grant_type: 'authorization_code',
    },
  });
};

exports.userLogin = function(data) {
  return fetcher.post('/users/login', {
    loginType: 0,
    outerId: data.openid,
    authToken: data.access_token,
  });
};

exports.getDisplayList = function(params) {
  return fetcher.get('/displays/infos', {
    params,
  });
};

exports.checkAuth = function() {
  return fetcher.get('/auths/FEATURE_QUERY_AUTH');
};

exports.getUserRegistryInfo = function() {
  return fetcher.get('/users/general');
};

exports.getQRCode = function(params) {
  return fetcher.post('/mini/qr/code', params);
};

exports.platformApply = function(params) {
  return fetcher.post('/platform/apply/users', params);
};

exports.fetcher = fetcher;
